<template>
  <div id="chatWrapper" ref="chatWrapper" style="padding: 0; height: 100%;">
    <div
      v-if="messagesParsed.length > 0"
      class="chat-container"
      ref="chatContainer"
      @scroll="onScroll"
    >
      <message
        v-for="message in messagesParsed"
        :key="message.idMensaje"
        :message="message"
        @imageLoad="scrollToEnd"
        @reload="$emit('reload')"
      ></message>

      <!-- <v-fab-transition>
        <v-btn
          v-show="progress > 0.1"
          color="primary"
          fab
          dark
          small
          absolute
          bottom
          right
          @click.stop="scrollToEnd"
        >
          <v-icon>mdi-arrow-down-thick</v-icon>
        </v-btn>
      </v-fab-transition> -->
    </div>

    <div v-else class="d-flex chat-container empty">
      <v-subheader>
        <v-icon left small>mdi-message-plus-outline</v-icon>
        Aún no hay mensajes...</v-subheader
      >
    </div>

    <message-input
      v-if="!noInput"
      ref="messageInput"
      :key-name="keyName"
      :item-key="itemKey"
      @reload="$emit('reload')"
    />
    <v-btn :loading="loading" tile small text @click.stop="descargar"
      >Exportar</v-btn
    >
  </div>
</template>

<script>
import palette from "@/modules/impagados/services/colorPalette.js";
import html2canvas from "html2canvas";
import { downloadFile } from '@/utils/index.js';

export default {
  props: {
    messages: Array,
    itemKey: Number | String,
    keyName: String,
    noInput: Boolean,
  },
  components: {
    Message: () => import("@/components/Chat/Message.vue"),
    MessageInput: () => import("@/components/Chat/MessageInput.vue"),
  },
  data() {
    return {
      progress: 0,
      loading: false,
    };
  },
  computed: {
    messagesParsed() {
      let map = {},
        roots = [],
        i;

      for (i = 0; i < this.messages.length; i += 1) {
        map[this.messages[i].idMensaje] = i; // initialize the map
        this.messages[i].children = []; // initialize the children
        this.messages[i].userColor =
          palette[
            ((this.messages[i].idUsuario % palette.length) + palette.length) %
              palette.length
          ];
      }

      this.messages.forEach((node) => {
        if (node.idParent && node.idParent != "0") {
          // if you have dangling branches check that map[node.parentId] exists
          node.extension = node.archivo.split(".")[
            node.archivo.split(".").length - 1
          ];
          this.messages[map[node.idParent]].children.push(node);
        } else {
          roots.push(node);
        }
      });

      return roots;
    },
  },
  mounted() {
    const dropzone = this.$refs.chatWrapper;

    dropzone.addEventListener("dragenter", (e) => {
      e.preventDefault();
      this.$refs.messageInput.message.showFiles = true;
    });

    // this.$nextTick(() => this.scrollToEnd())
  },
  methods: {
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    onScroll() {
      const progress = Math.abs(
        this.$refs.chatContainer.scrollTop /
          (this.$refs.chatContainer.scrollHeight -
            this.$refs.chatContainer.clientHeight)
      );
      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }
    },
    async descargar() {
      this.loading = true;
      this.$refs.chatContainer.style.maxHeight = "unset";
      await this.$nextTick();

      const canvas = await html2canvas(this.$refs.chatContainer)
      canvas.toBlob((b) => downloadFile(b, true));
      
      await this.$nextTick();
      this.$refs.chatContainer.style.maxHeight = null;
      this.loading = false;
    },
  },
};
</script>

<style>
#chatWrapper {
  display: flex;
  flex-direction: column;
}
.chat-container {
  box-sizing: border-box;
  min-height: 200px;
  max-height: 700px;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  background-color: #e4e4e4;
}
.chat-container.empty {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.theme--dark .chat-container {
  background-color: #272727;
}
</style>
